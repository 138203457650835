import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.png'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Hi, I'm <br /> Rizda Dwi Prasetya!
        </BigTitle>
        <Subtitle>A Software Engineer, mostly working with web platform. Also fluent with automations. For more details checkout <a href="https://id.linkedin.com/in/rizdadwi/">my LinkedIn</a>.</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>Repos & Hobby Projects</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Midtrans NodeJS API Client"
            link="https://github.com/Midtrans/midtrans-nodejs-client"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            Open source official Node JS API client/library for Midtrans Payment API.
          </ProjectCard>
          <ProjectCard
            title="Midtrans Python API Client"
            link="https://github.com/Midtrans/midtrans-python-client"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            Open source official Python API client/library for Midtrans Payment API.
          </ProjectCard>
          <ProjectCard
            title="Midtrans WooCommerce"
            link="https://github.com/veritrans/SNAP-Woocommerce"
            bg="linear-gradient( 109.6deg,  rgba(17,94,199,1) 11.2%, rgba(63,241,152,0.79) 100.6% )"
          >
            Open source plugin to allow secure online payment on WooCommerce store, without customer ever need to leave the WooCommerce store.
          </ProjectCard>
          <ProjectCard
            title="Quran Mini"
            link="https://quran.rizda.win/"
            bg="linear-gradient( 109.6deg,  rgba(17,111,255,1) 11.2%, rgba(112,226,226,1) 91.1% )"
          >
            Mini Quran web app built for my personal use, focusing on simplicity and configurable font size.
          </ProjectCard>
          <ProjectCard
            title="CMSheet.js"
            link="https://github.com/rizdaprasetya/cmsheet.js"
            bg="linear-gradient( 109.5deg,  rgba(24,175,231,1) 11.2%, rgba(67,82,186,1) 74.6% );"
          >
            Frontend JS library to fetch Google Sheet content and apply it to replace web page content in simple way.
          </ProjectCard>
          <ProjectCard
            title="Post it out!"
            link="https://postit.rizda.win/"
            bg="linear-gradient(to right, #9561e2 0%, #7886d7 100%)"
          >
            Simple & focused writing platform that requires no sign up, hosted on static HTML+JS with Google Sheet as database.
          </ProjectCard>
          <ProjectCard
            title="WA Sender"
            link="https://wasender.rizda.win/"
            bg="linear-gradient(to right, #a779e9 0%, #00FFEE 100%)"
          >
            Simple web app to help you send whatsapp to an unsaved number (or peek their whatsapp profile).
          </ProjectCard>
          <ProjectCard
            title="More on Github"
            link="https://github.com/rizdaprasetya/"
            bg="linear-gradient( 109.6deg,  rgba(17,94,199,1) 11.2%, rgba(63,241,152,0.79) 100.6% )"
          >
            Other things that I put on github
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutDesc>
          Currently <a href="https://id.linkedin.com/in/rizdadwi/">working at Midtrans</a> to empower thousands of business (SMEs, government, to tech Unicorns) with seamless payment and related services.
          <br />
          I play guitar and do graphic design as a hobby.
        </AboutDesc>
        <AboutHero>
          {/*<Avatar src={avatar} alt="John Doe" />*/}
          <AboutSub>
            - Sometimes I craft stuff, other times I break stuff :) <br /> <small>*in order to understand them</small>
          </AboutSub>
        </AboutHero>
      </About>
      {/**/}
      <Contact offset={4}>
        <Inner>
          <Title>Blog</Title>
          <ContactText>
            The place where I like to shout out :{' '}
            <a href="https://blog.rizda.win">checkout my Blog!</a>
          </ContactText>

          <Title>Get in touch</Title>
          <ContactText>
            Say <a href="https://id.linkedin.com/in/rizdadwi/">Hi</a> or find me on other platforms:{' '}
            <a href="https://github.com/rizdaprasetya/">Github</a> &{' '}
            <a href="https://id.linkedin.com/in/rizdadwi/">LinkedIn</a>
          </ContactText>
        </Inner>
        <Footer>
           ~ Crafted with ❤️ by <a href="https://github.com/rizdaprasetya">Rzd</a>.{' '} ~ 
          {/*<a href="https://github.com/rizdaprasetya">Github Repository</a>.*/}
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
